/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        console.log("SET THE CSS!");
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        //FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function () {
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('is-external').attr({
          'target': '_blank',
          'rel': 'noopener'
        });

        // IE Specific
        if (navigator.userAgent.match(/Trident.*rv:11\./)) {
          $('body').addClass('ie11');
        }

        if (navigator.userAgent.match(/Edge\/\d./i)) {
          $('body').addClass('ie');
        }

        /* No longer needed - added fancybox to nav menu item */
        if ($('.nav__item--get-started').length) {
          //$('.nav__item--get-started a').attr('data-fancybox', '');
        }

        // Inject Get Started button into menus
        if ($('#menu-right-primary-navigation').length) {
          $('#menu-right-primary-navigation').append('<li class="nav__item nav__item--get-started"><a href="#checkout" data-fancybox>Get Started</a></li>');
        }

        if ($('#menu-mobile-primary-navigation').length) {
          $('#menu-mobile-primary-navigation').append('<li class="nav__item nav__item--get-started"><a href="#checkout" data-fancybox>Get Started</a></li>');
        }

        // Enable fancybox on checkout buttons
        if ($('.btn--checkout').length) {
          $('.btn--checkout').each(function () {
            $(this).attr('data-fancybox', '');
          });
        }

        // Navigation
        $('.js-toggle-nav').click(function (e) {
          e.preventDefault();

          $('.header').toggleClass('nav-is-visible');
        });

        // Dropdown
        $('.js-toggle-dropdown').click(function (e) {
          e.preventDefault();

          $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
        });

        // Initiate lazy load
        new LazyLoad();

        // Carousel(s)
        if ($('.js-carousel-features').length) {
          if (window.matchMedia('(max-width: 1023px)').matches) {
            $('.js-carousel-features').slick({
              autoplay: false,
              arrows: false,
              dots: true,
              rows: 0,
              slidesToShow: 1,
              slidesToScroll: 1
            });
          }
        }

        if ($('.js-carousel-project').length) {
          $('.js-carousel-project').slick({
            autoplay: false,
            arrows: true,
            dots: true,
            fade: true,
            prevArrow: '<button type="button" class="slick-prev"></button>',
            nextArrow: '<button type="button" class="slick-next"></button>',
            rows: 0,
            slidesToShow: 1,
            slidesToScroll: 1
          });
        }

        if ($('.js-carousel-hero').length) {
          $('.js-carousel-hero').slick({
            accessibility: true,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            fade: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 0,
          });
        }

        // Fancybox
        if ($('.btn--close').length) {
          $('.btn--close').fancybox().close();
        }

        // Gravity Forms
        var gravityFormsError = $('.gform_validation_error');

        if (gravityFormsError.length) {
          $('body').addClass('gf-error');
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var viewportWidth = $(window).width();
        var maxViewport = 1024;

        // Hero positioning based on header height
        /*
        var header = $('.header');
        var headerHeight = header.outerHeight(true);
        var hero = $('.hero');

        $(window).load(function () {
          hero.css({
            'position': 'relative',
            'top': -headerHeight,
            'margin-bottom': -headerHeight
          });
        });
        */

        // Hero background
        var hero = $('.hero');
        var heroMobileBG = hero.data('mobile');
        var heroDesktopBG = hero.data('desktop');

        if (viewportWidth < maxViewport) {
          hero.css('background-image', 'url(' + heroMobileBG + ')');
        } else {
          hero.css('background-image', 'url(' + heroDesktopBG + ')');
        }

        $('.hero__slide').each(function(index) {
          var slide = $(this);

          var slideMobileBG = slide.data('mobile');
          var slideDesktopBG = slide.data('desktop');

          if (viewportWidth < maxViewport) {
            slide.css('background-image', 'url(' + slideMobileBG + ')');
          } else {
            slide.css('background-image', 'url(' + slideDesktopBG + ')');
          }
        });

        // Toggle Features
        if ($('.js-toggle-more-features').length) {
          $('.js-toggle-more-features').click(function (e) {
            e.preventDefault();

            var buttonTxt = $(this).parent().parent('.more--features').hasClass('is-visible') ? '<i class="fal fa-plus"></i> View More' : '<i class="fal fa-minus"></i> View Less';

            $(this).html(buttonTxt);

            $(this).parent().parent('.more--features').toggleClass('is-visible');
          });
        }

        /*
        // Detect window scroll
        var jsScrollCheck = $('.js-scroll-check');

        $('window').on('scroll', function() {
          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(this).height();

          // Add class to body
          if (viewportTop > 1) {
            $('body').addClass('is-scrolling');
          } else {
            $('body').removeClass('is-scrolling');
          }

          // Detect if element is visible in viewport
          jsScrollCheck.each(function () {
            var jsScrollCheckTop = $(this).offset().top;

            if (jsScrollCheckTop < viewportBottom) {
              $(this).addClass('is-visible');
            }
          });
        });
        */
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
